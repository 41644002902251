import React, { useState, useEffect } from "react";
import "./cardgen.css";

let apiUrl;
if (process.env.NODE_ENV === "production") {
  apiUrl = "https://tools-for-designing-games-backend.vercel.app";
} else {
  apiUrl = "http://localhost:3001";
}

function showToast(message, type = "success") {
  const toast = document.getElementById("toast");
  toast.textContent = message;
  toast.classList.add("active");

  if (type === "error") {
    toast.classList.add("error");
  }

  setTimeout(() => {
    toast.classList.remove("active");
    toast.classList.remove("error");
  }, 5000);
}

function App() {
  const [project, setProject] = useState(null);

  useEffect(() => {
    document.title = "Game Card Generator";
  }, []);

  async function handleProjectSave(event) {
    event.preventDefault();

    const name = event.target.elements.name.value;
    const html = event.target.elements.html.value;
    const css = event.target.elements.css.value;

    const response = await fetch(`${apiUrl}/project`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, html, css }),
    });

    const data = await response.json();
    if (!response.ok) {
      const message = data.message || "An error occurred";
      showToast(message);
      return;
    } else {
      showToast("Project saved successfully");
    }
    setProject(data);
  }

  async function handleProjectGenerate(event) {
    event.preventDefault();

    // Create a new FormData instance
    const formData = new FormData();

    // Append files to formData. Here, 'file' is the name of the form field, and event.target.files[0] is the file
    formData.append("media", document.querySelector("#media").files[0]);
    formData.append("data", document.querySelector("#data").files[0]);

    // Send the request to the server
    const response = await fetch(`${apiUrl}/project/${project._id}/generate`, {
      method: "POST",
      body: formData, // This is the file data
    });

    // Handle the response
    if (!response.ok) {
      const message = "An error occurred";
      showToast(message);
      return;
    }

    const json = await response.json();
    console.log(json[0].data);

    // Assuming `response` is the response from the server
    const blob = new Blob([new Uint8Array(json[0].data)], {
      type: "image/png",
    });
    const url = URL.createObjectURL(blob);

    // Create a link and programmatically click it to download the file
    const link = document.createElement("a");
    link.href = url;
    link.download = "screenshot.png"; // or any other filename you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="app">
      <div className="app-description">
        <h1>Game Card Generator</h1>
        <p className="desc">
          This tool allows you to create a layout using HTML and CSS, and then
          generate a card using media assets and layout data.
        </p>
        <p className="desc">
          We are very early in development, so please be patient with us. If
          you'd like to help us out (either with feedback or as a developer),
          please reach out to{" "}
          <a href="mailto:drew@clovis.marketing">drew@clovis.marketing</a>
        </p>
      </div>
      <div className="main-canvas">
        <form id="save-project-form" onSubmit={handleProjectSave}>
          <label htmlFor="name">Project Name</label>
          <input type="text" id="name" defaultValue={"Unnamed Project"} />
          <label htmlFor="html">HTML</label>
          <textarea
            id="html"
            defaultValue={'<div class="tfdg-asset box">{{1}}</div>'}
          ></textarea>
          <label htmlFor="css">CSS</label>
          <textarea
            id="css"
            defaultValue={
              ".box { width: 100px; height: 100px; background-color: green; }"
            }
          ></textarea>
          <button type="submit">Save</button>
          <p id="info">
            Please note, all saves are public. Your assets (photos, icons, etc.)
            will not be saved, but your HTML and CSS will. Other people will be
            able to use your layouts for their projects.
          </p>
          <p id="toast"></p>
        </form>
        <div className="preview-window">
          <iframe
            title="Project Preview"
            srcDoc={`
                <style>
                  img { background-color: rgba(255,255,255,0.5); border: 1px solid white; }
                  ${project?.css}
                </style>
                ${project?.html}
              `}
          ></iframe>
        </div>
      </div>

      {!project ? (
        <></>
      ) : (
        <form id="generate-form" onSubmit={handleProjectGenerate}>
          <label htmlFor="media">Media Assets</label>
          <input type="file" id="media" accept="image/*" multiple />
          <label htmlFor="data">Layout data (CSV only)</label>
          <input type="file" id="data" accept=".csv" />
          <button type="submit">Generate</button>
        </form>
      )}
    </div>
  );
}

export default App;
